import { render, staticRenderFns } from "./Identification.vue?vue&type=template&id=04bdc9bd&scoped=true&"
import script from "./Identification.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Identification.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Identification.vue?vue&type=style&index=0&id=04bdc9bd&prod&scoped=true&lang=scss&"
import style1 from "./Identification.vue?vue&type=style&index=1&id=04bdc9bd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04bdc9bd",
  null
  
)

export default component.exports